<template>
  <div>
    <el-dialog
        title="接单"
        :visible.sync="isOpenModal"
        :close-on-click-modal="false"
        width="800px"
        @closed="closeModal" @open="openModal">
      <div style="width: 90%">
        <ch-table :render-option="option" :data="tableList" class="mt-10"  :loading="isTableLoading" ref="table" empty-text="暂无服务人员"
                  :border="true" :header-cell-style="{background:'#F2F6FC'}" @select-all="selectAll" @selection-change="tableSelect"
                  :props="{width: '100%', maxHeight: '400px' ,size: 'mini'}">
        </ch-table>
        <span slot="footer" class="flex_con">
          <ch-button @click="closeModal" type="cancel">取消</ch-button>
          <ch-button
              @click="handleModal"
              :loading="isLoading"
              type="ok" >确定</ch-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    getListFunction: {
      type: Function
    }
  },
  data() {
    return {
      modalForm: {},
      tableList: [],
      isOpenModal: false,
      isLoading: false,
      isTableLoading: false,
      rules: {
        remark: [{required: true, message: "请输入更换原因", trigger: ["blur", "change"],},],
      },
      searchParams: null,
      tableSelectList: [],
    }
  },
  computed: {
    option: function () {
      return [
        {column: "type", prop: "", type: "selection", width: "25px", selectable: this.selectable},
        // {column: "type", label: "工号", prop: "goodsName",  width: "70",},
        {column: "text", label: "服务人员姓名", prop: "name",},
        {column: "text", label: "最近时间", prop: "lastOrderTime", showOverflowTooltip: true,},
        {column: "text", label: "订单地址", prop: "lastOrderAddress", showOverflowTooltip: true,},
        {column: "text", label: "状态", prop: "_isBooked", width: 70},
      ]
    },
    formOption: function () {
      return [
        {
          type: 'textarea',
          label: '更换原因：',
          prop: 'remark',
          placeholder: "请输入更换原因",
          props: {maxlength: 200, showWordLimit: true}
        },
      ]
    }
  },
  methods: {
    // 打开前处理
    openModal() {
      this.$nextTick(() => {
        this.$refs.modalForm?.clearValidate();
      })
    },
    open(row) {
      this.searchParams = JSON.parse(JSON.stringify(row))
      this.isOpenModal = true
      this.getTableList()
    },
    getTableList(params = this.searchParams) {
      this.isTableLoading = true;
      this.$curl.post('/hm/order/bookingStaffList', params).then(res => {
        this.tableList = res.data.map(item => {
          return {
            ...item,
            _isBooked: item.isBooked ? '可预约' : '不可预约'
          }
        });
      }).finally(() => {
        this.isTableLoading = false
      })
    },
    handleModal() {
      if(this.tableSelectList.length === 0 ){
        this.$message.error('请选择接单的服务人员！')
        return
      }
      this.isLoading = true
      this.$curl.post('/hm/order/serviceAcceptAndChange', {
        serviceOrderId: this.searchParams.serviceOrderId,
        serviceStaffId: this.tableSelectList[0].id,
      }).then(() => {
        this.getListFunction()
        this.$message.success("操作成功!");
        this.closeModal()
      }).finally(() => {
        this.isLoading = false
      })
    },
    //选中方法
    tableSelect(selection) {
      this.tableSelectList = selection
      if (selection.length > 1) {
        let del_row = selection.shift()
        this.$refs.table.$children[0].toggleRowSelection(del_row, false)
      }
    },
    //判断选中数量
    selectAll(selection) {
      selection.length = 1
    },
    selectable(row) {
      return row.isBooked
    },
    // 关闭前清除数据
    closeModal() {
      this.modalForm = {}
      this.isOpenModal = false
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  padding-bottom: 10px;
}

::v-deep(.el-table__header-wrapper  .el-checkbox) {
  display: none
}
</style>