<template>
  <div>
    <el-dialog
        title="改期接单"
        :visible.sync="isOpenModal"
        :close-on-click-modal="false"
        width="800px"
        @closed="closeModal" @open="openModal">
      <div style="width: 90%">
        <div class="title">原服务时间：{{info.startTime}}</div>
        <div class="time-select">
          <div><span style="color: #EB0A0A;margin-right: 3px;">*</span>请选择改期时间：</div>
          <el-date-picker
              v-model="date" :picker-options="pickerOptions"
              type="date" size="small" value-format="yyyy-MM-dd"
              placeholder="选择改期日期">
          </el-date-picker>
          <el-select v-model="time" style="margin-left: 5px" clearable size="small" placeholder="请选择改期时间">
            <el-option
                v-for="item in newTimeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <ch-button @click="getTableList" type="ok" style="margin-left: 5px">刷新</ch-button>
        </div>
        <div class="tip">请确认与用户沟通后才能去改期接单！</div>
        <ch-table :render-option="option" :data="tableList" class="mt-10"  :loading="isTableLoading" ref="table" empty-text="暂无服务人员"
                  :border="true" :header-cell-style="{background:'#F2F6FC'}" @select-all="selectAll" @selection-change="tableSelect"
                  :props="{width: '100%', maxHeight: '400px' ,size: 'mini'}">
        </ch-table>
        <span slot="footer" class="flex_con">
          <ch-button @click="closeModal" type="cancel">取消</ch-button>
          <ch-button
              @click="handleModal"
              :loading="isLoading"
              type="ok" >确定</ch-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  props: {
    getListFunction: {
      type: Function
    }
  },
  data(){
    return {
      tableList: [],
      isOpenModal: false,
      isLoading: false,
      isTableLoading: false,
      rules: {
        remark: [{required: true, message: "请输入更换原因", trigger: ["blur", "change"],},],
      },
      searchParams:{},
      tableSelectList: [],
      info: {},
      timeList: [
          {label: '08:00', value: '08:00'},
          {label: '08:30', value: '08:30'},
          {label: '09:00', value: '09:00'},
          {label: '09:30', value: '09:30'},
          {label: '14:00', value: '14:00'},
          {label: '14:30', value: '14:30'},
          {label: '15:30', value: '15:30'},
          {label: '16:30', value: '16:30'},
          {label: '18:00', value: '18:00'},
          {label: '19:00', value: '19:00'},
          {label: '19:30', value: '19:30'},
          {label: '20:00', value: '20:00'},
      ],
      time: '',
      date: '',
      unavailableTime: {},
      pickerOptions: {
        disabledDate(time) {
          // 获取当前日期
          const today = new Date();
          const endDay = new Date(today);
          endDay.setDate(today.getDate() + 6);
          today.setDate(today.getDate() - 1)
          if (time < today || time > endDay) {
            return true;
          } else {
            return false;
          }
        },
      }
    }
  },
  computed: {
    option: function () {
      return [
        {column: "type", prop: "", type: "selection", width: "25px",selectable:this.selectable},
        // {column: "type", label: "工号", prop: "goodsName",  width: "70",},
        {column: "text", label: "服务人员姓名", prop: "name",},
        {column: "text", label: "最近时间", prop: "lastOrderTime",showOverflowTooltip: true,},
        {column: "text", label: "订单地址", prop: "lastOrderAddress",showOverflowTooltip: true,},
        {column: "text", label: "状态", prop: "_isBooked",width: 70},
      ]
    },
    newTimeList: function () {
      if(!this.date){
        return []
      }else {
        let index = dayjs(this.date).format('MM-DD')
        return this.timeList.filter(item => {
          return !this.unavailableTime[index].includes(item.value)
        })
      }
    }

  },
  methods: {
    // 打开前处理
    openModal() {
      this.$nextTick(() => {
        this.$refs.modalForm?.clearValidate();
      })
    },
    open(row){
      this.info = JSON.parse(row)
      this.isOpenModal = true
      this.searchParams = {
        goodsId: this.info.goodsId,
        serviceOrderId: this.info.id,
      }
      this.getUnavailableOrder(this.info.orderId)
    },
    getUnavailableOrder(id) {
      this.$curl.get('/hm/order/unavailableOrder/' + id).then(res => {
        this.unavailableTime = res.data
      })
    },
    getTableList() {
      if (!this.date || !this.time) {
        this.$message.error('请选择改期日期和时间！')
        return
      }
      this.searchParams.localDateTimeStart = this.date + ' '+ this.time + ':00'
      this.isTableLoading = true;
      this.$curl.post('/hm/order/bookingStaffList',this.searchParams).then(res => {
        this.tableList = res.data.map(item => {
          return {
            ...item,
            _isBooked: item.isBooked ? '可预约' : '不可预约'
          }
        });
      }).finally(() => {
        this.isTableLoading = false
      })
    },
    handleModal() {
      if(this.tableSelectList.length === 0 ){
        this.$message.error('请选择服务人员！')
        return
      }
      this.isLoading = true
      this.$curl.post('/hm/order/serviceChangeAndAccept', {
        serviceOrderId: this.searchParams.serviceOrderId,
        serviceStaffId: this.tableSelectList[0].id,
        changeTime:this.searchParams.localDateTimeStart
      }).then(() => {
        this.getListFunction()
        this.$message.success("操作成功!");
        setTimeout(() => {
          this.closeModal()
        })
      }).finally(()  => {
        this.isLoading = false
      })
    },
    //选中方法
    tableSelect(selection){
      this.tableSelectList = selection
      if (selection.length >1) {
        let del_row = selection.shift()
        this.$refs.table.$children[0].toggleRowSelection(del_row, false)
      }
    },
    //判断选中数量
    selectAll(selection) {
      selection.length = 1
    },
    selectable(row){
      return row.isBooked
    },
    // 关闭前清除数据
    closeModal() {
      this.searchParams = {}
      this.isOpenModal = false
      this.isLoading = false
      this.date = ''
      this.time = ''
      this.tableSelectList = []
    },
  },
}
</script>

<style lang="scss" scoped>
.title{
  font-weight: bold;
  padding-bottom: 10px;
}
.time-select {
  display: flex;
  align-items: center;
  font-weight: bold;
}
.tip {
  color: #EB0A0A;
  font-size: 12px;
  margin-bottom: 10px;
}
::v-deep(.el-table__header-wrapper  .el-checkbox){
  display:none
}
</style>