<template>
  <div>
    <ch-layout page search>

      <template #search>
        <div style="width: 100%;">
          <ch-search
              :render-option="searchOption" :model="searchForm"
              :props="{ labelWidth: '66px',}">
          </ch-search>
        </div>
      </template>
      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  :props="{width: '100%', height: '100%', size: 'mini'}">
          <template v-slot:amount="scope">
            <span>
              {{scope.row.amount}}
              <el-tooltip class="item" effect="dark" content="保证好服务质量！" placement="top">
                <ch-icon v-if="scope.row.payType===3" style="font-size: 20px;margin-right: 5px" name="payTypeBefore" />
              </el-tooltip>
            </span>
          </template>
          <template v-slot:status="scope">
            <div v-if="scope.row.status===1" style="color: #3d7fff">{{status[scope.row.status]}}</div>
            <div v-else-if="scope.row.status===4" style="color: #12a182">{{status[scope.row.status]}}</div>
            <div v-else-if="scope.row.status===5" style="color: #f1ca17">{{status[scope.row.status]}}</div>
            <div v-else-if="scope.row.status===7|| scope.row.status===11" style="color: #EB0A0A">{{status[scope.row.status]}}</div>
            <div v-else>{{status[scope.row.status]}}</div>
          </template>
          <template v-slot:handle="scope">
            <ch-button type="link" @click="toPage(scope.row.id)">详情</ch-button>
            <ch-button type="link" v-if="scope.row.status===3||scope.row.status===4" @click="openBilling(scope.row.id)">开票</ch-button>
            <ch-button type="link" v-if="scope.row.status===7" @click="toAccept(scope.row)">接单</ch-button>
            <ch-button type="link" v-if="scope.row.status===7" @click="openRescheduleOrders(scope.row)">改期接单</ch-button>
            <ch-button type="link" v-if="(scope.row.status===7||scope.row.status===1||scope.row.status===8)&&scope.row.serverStaffName" @click="openChange(scope.row)">更换人员</ch-button>
            <ch-button type="delete" v-if="scope.row.canCancel" @click="openCancel(scope.row.id)">取消</ch-button>
            <ch-button type="delete" v-if="scope.row.needAudit && treeSelect.settlementAudit" @click="openSettlementAudit(scope.row.id)">结算审核</ch-button>
          </template>
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page
            type="old"
            ref="page"
            :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>
    <change-service ref="changeService" :get-list-function="getTableList" />
    <take-orders ref="takeOrders" :get-list-function="getTableList" />
    <cancel-order ref="cancelOrder" :get-list-function="getTableList" />
    <reschedule-orders ref="rescheduleOrders" :get-list-function="getTableList" />
    <el-dialog title="结算审核" :visible.sync="settlementAudit" width="500px" :close-on-click-modal="false" @close="handleCloseSettlementAudit">
      <ch-form :render-option="formOptionSettlement" :model="modalFormSettlement" :rules="ruleSettlement"
               :props="{ labelWidth: '100px' , paddingRight: '30px'}" style="width: 100%;"
               ref="modalFormSettlement">
      </ch-form>
      <span slot="footer" class="flex_con">
        <ch-button type="cancel" @click="handleCloseSettlementAudit">取消</ch-button>
        <ch-button type="ok" :loading="loadingSettlement" @click="handleSubmitSettlement">确定</ch-button>
      </span>
    </el-dialog>
    <el-dialog title="发票信息" :visible.sync="showBilling" width="500px" :close-on-click-modal="false" @close="handleCloseBilling">
      <ch-form :render-option="formOptionBilling" :model="modalFormBilling" :rules="ruleBilling"
               :props="{ labelWidth: '100px' , paddingRight: '30px'}" style="width: 100%;"
               ref="modalFormBilling">
      </ch-form>
      <span slot="footer" class="flex_con">
        <ch-button type="cancel" @click="handleCloseBilling">取消</ch-button>
        <ch-button type="ok" :loading="loadingSettlement" @click="handleSubmitBilling">确定</ch-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import changeService from "@/views/orderManagement/serviceOrders/changeService.vue";
import takeOrders from "@/views/orderManagement/serviceOrders/takeOrders.vue";
import cancelOrder from "@/views/orderManagement/serviceOrders/cancelOrder.vue";
import rescheduleOrders from "@/views/orderManagement/serviceOrders/rescheduleOrders.vue";
import { mapGetters,mapMutations } from "vuex";
import {getTreeSelect} from "@/utils/utils";
export default {
  components: {
    changeService,takeOrders,cancelOrder,rescheduleOrders
  },
  data() {
    return {
      searchForm:{
        goodsName: "",
        orderNo: "",
        staffName: "",
        status: '',
        userName: "",
        userPhone: "",
        invoiceNo: "",
      },
      tableList: [],
      isTableLoading: false,
      status:['待支付','待服务','已取消','已评价','已完成','服务中','待验收','待接单','待签到','申诉中','夜间待支付','爽约'],
      treeSelect: {
        takeOrders: false,
        replacement: false,
        cancel: false,
        settlementAudit: false,
      },
      settlementAudit: false, // 结算审核
      modalFormSettlement: {
        status: 1,
      },
      ruleSettlement:{
        status: [
          { required: true, message: '请选择审核状态', trigger: ['change', 'blur'] },
        ],
        remark: [
          { required: true, message: '请输入不通过原因', trigger: ['change', 'blur'] },
        ],
      },
      loadingSettlement: false,
      showBilling: false,
      modalFormBilling: {
        invoiceNo: '',
        serviceOrderId: '',
      },
      ruleBilling: {
        invoiceNo: [{ required: true, message: '请输入发票号', trigger: ['change', 'blur'] },],
      }
    }
  },
  computed: {
    searchOption: function () {
      return [
        {type: 'input', label: '服务订单号：', prop: 'orderNo', placeholder: "请输入服务订单号",labelWidth: '80px',width: '220px'},
        {type: 'input', label: '用户姓名：', prop: 'userName', placeholder: "请输入用户姓名",},
        {type: 'input', label: '用户手机号：', prop: 'userPhone', placeholder: "请输入用户手机号",labelWidth: '80px'},
        {type: 'input', label: '服务名称：', prop: 'goodsName', placeholder: "请输入服务名称",},
        {type: 'input', label: '服务人员：', prop: 'staffName', placeholder: "请输入服务名称",},
        {type: 'input', label: '发票号：', prop: 'invoiceNo', placeholder: "请输入发票号",},
        {
          type: "select", label: "状态：", prop: "status", placeholder: "请选择状态",labelWidth: '44px',
          name: "label", value: "value", option: [
            {value: 0, label: "待支付"},
            {value: 1, label: "待服务"},
            {value: 2, label: "已取消"},
            {value: 3, label: "已评价"},
            {value: 4, label: "已完成"},
            {value: 5, label: "服务中"},
            {value: 6, label: "待验收"},
            {value: 7, label: "待接单"},
            {value: 8, label: "待签到"},
            {value: 9, label: "申诉中"},
            {value: 10, label: "夜间待支付"},
            {value: 11, label: "爽约"},
          ]
        },
        {
          type: 'button', button: [
            {text: '查询', onClick: this.searchHandle},
            {text: '重置', onClick: this.searchHandle},
          ]
        },

      ]
    },
    tableOption: function () {
      return [
        {column: "type", prop: "", type: "selection", width: "25px"},
        {column: "type", label: "序号", prop: "", type: "index", width: "70",},
        {column: "text", label: "服务订单号", prop: "serverNumber",showOverflowTooltip:true,width: '170px'},
        {column: "text", label: "用户姓名", prop: "userName",showOverflowTooltip:true,},
        {column: "text", label: "手机号码", prop: "telNumber",showOverflowTooltip:true,props: {minWidth: '100px',}},
        {column: "text", label: "服务地址", prop: "_detailInfo",showOverflowTooltip:true, width: 200},
        {column: "text", label: "服务名称", prop: "goodsName",showOverflowTooltip:true,},
        {column: "slot", label: "本单金额", slotName: "amount",showOverflowTooltip:true,},
        {column: "text", label: "下单时间", prop: "createTime",showOverflowTooltip:true,props: {minWidth: '140px',}},
        {column: "text", label: "服务开始时间", prop: "startTime",showOverflowTooltip: true,props: {minWidth: '140px',}},
        {column: "text", label: "服务人员", prop: "serverStaffName",showOverflowTooltip:true,},
        {column: "text", label: "发票号", prop: "invoiceNo",showOverflowTooltip:true,},
        {column: "slot", label: "状态", slotName: "status",showOverflowTooltip:true,},
        {column: "slot", label: "操作", slotName: "handle", width: '180px', fixed: 'right'},
      ];
    },
    formOptionSettlement: function () {
      return [
        {type: 'radio', label: '审核状态：', prop:'status',option: [
            {label: '通过', value: 1},
            {label: '不通过', value: 2},
          ],
        },
        {type: 'textarea', label: '不通过原因', prop: 'remark', placeholder: "请输入不通过原因",props: {maxlength:100,showWordLimit:true},show: this.modalFormSettlement.status === 2},
      ]
    },
    formOptionBilling: function () {
      return [
        {type: 'textarea', label: '发票号', prop: 'invoiceNo', placeholder: "请输入发票号",props: {maxlength:20,showWordLimit:true}},
      ]
    },
    ...mapGetters(['flush'])
  },
  created() {
    // 获取操作显示权限
    getTreeSelect(this.$route.name).then((res) => {
      this.treeSelect = res
    })
  },
  mounted() {
    this.getTableList()
  },
  watch: {
    flush: function (val) {
      if(val) {
        this.getTableList()
        this.saveFlush(false)
      }
    }
  },
  methods: {
    ...mapMutations(['saveFlush']),
    // 查询
    searchHandle() {
      this.getTableList()
    },
    getTableList(params=this.searchForm) {
      this.isTableLoading = true;
      this.$refs?.page?.getServerList("/hm/order/serviceList",params).then((res) => {
        let row = res.rows
        this.tableList = row.map((item) => {
          return {
            ...item,
            _useNumber: item.useNumber +'/'+ item.number,
            _status: this.status[item.status],
            _detailInfo: item.provinceName + item.cityName + item.countyName + item.detailInfo
          }
        })
      }).finally(() => {this.isTableLoading = false});
    },
    // 接单
    toAccept(row){
      if(row.serverStaffName){
        this.$confirm('是否接单?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$curl.post('/hm/order/serviceAccept/'+row.id).then(()=>{
            this.$message({
              type: 'success',
              message: '接单成功!'
            });
            this.getTableList()
          })
        })
      }else {
        let rows = {
          serviceOrderId: row.id,
          localDateTimeStart:row.startTime,
          goodsId:row.goodsId,
          // serverStaffName:row.serverStaffName,
          oldStaffId:row.serviceStaffId
        }
        this.$refs.takeOrders.open(rows)
      }
    },
    // 跳转详情
    toPage(orderId){
      this.$router.push({ name: "serviceDetail", query: { orderId } });
    },
    // 更换人员
    openChange(row){
      let rows = {
        serviceOrderId: row.id,
        localDateTimeStart:row.startTime,
        goodsId:row.goodsId,
        serverStaffName:row.serverStaffName,
        oldStaffId:row.serviceStaffId
      }
      this.$refs.changeService.openChange(rows)
    },
    // 改期接单
    openRescheduleOrders(row){
      this.$refs.rescheduleOrders.open(JSON.stringify(row))
    },
    // 取消预约
    openCancel(id){
      this.$refs.cancelOrder.openModal(id)
    },
    openSettlementAudit(id) {
      this.settlementAudit = true;
      this.modalFormSettlement.serviceOrderId = id;
    },
    handleCloseSettlementAudit() {
      this.settlementAudit = false;
      this.modalFormSettlement = {
        status: 1,
        remark: '',
        serviceOrderId: '',
      }
    },
    // 结算审核
    handleSubmitSettlement() {
      this.$refs.modalFormSettlement.validate((valid) => {
        if (valid) {
          this.loadingSettlement = true;
          this.$curl.post('/hm/hmServiceOrderSettlement/audit', this.modalFormSettlement).then((res) => {
            if(res.code === 200){
              let message = this.modalFormSettlement.status === 1 ? '审核通过成功' : '审核不通过成功'
              this.$message.success(message)
              this.getTableList()
              this.handleCloseSettlementAudit()
            }
          }).finally(() => {
            this.loadingSettlement = false;
          })
        }
      })
    },
    openBilling(id) {
      this.showBilling = true;
      this.modalFormBilling.serviceOrderId = id;
      this.$refs.modalFormBilling.clearValidate();
    },
    handleCloseBilling() {
      this.showBilling = false;
      this.modalFormBilling = {
        invoiceNo: '',
        serviceOrderId: '',
      }
    },
    handleSubmitBilling() {
      this.loadingSettlement = true;
      this.$curl.post('/hm/order/invoice', this.modalFormBilling).then(() => {
          this.$message.success('开票成功')
          this.showBilling = false;
          this.getTableList()
          setTimeout(() => {
            this.handleCloseBilling()
          })
      })
      .finally(() => {
        this.loadingSettlement = false;
      })
    }
  }
}

</script>

<style scoped lang="scss">

</style>